//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
// $primary: #4186e0;

@font-face {
  font-family: "FC-Iconic";
  src: url("../../fonts/FC-Iconic/FC Iconic Bold.ttf") format("TrueType");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "FC-Iconic";
  src: url("../../fonts/FC-Iconic/FC Iconic Italic.ttf") format("TrueType");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "FC-Iconic";
  src: url("../../fonts/FC-Iconic/FC Iconic Regular.ttf") format("TrueType");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FC-Iconic";
  src: url("../../fonts/FC-Iconic/FC Iconic Bold.ttf") format("TrueType");
  font-weight: 600;
  font-style: normal;
}

$font-family-monospace: "FC-Iconic", Arial, serif;
$font-family-sans-serif: "FC-Iconic", Arial, serif;

//primary color gradient
$flamenco: #ff8008;
$punch: #df4040;

$primary-gradient: linear-gradient(227.02deg, $flamenco -43.37%, $punch 74.12%);
$bg-primary: linear-gradient(258.68deg, $flamenco 0%, $punch 100%);

$primary: $punch;
$color-white: #fff;
$color-gray: #333333;
$color-gray-0: #f5f5f5;
$color-gray-1: #e2e2e2;
$color-gray-2: #999999;
$color-gray-4: #bbbbc5;
$color-gray-3: #656565;

//Breakpoint
$screen-xs-min: 0px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1440px;

@mixin xs {
  @media (min-width: 0px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
