@mixin font-gradient {
  background: $primary-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin outline-gradient {
  background: linear-gradient(white, white) padding-box,
    $primary-gradient border-box;
  border-radius: 12px;
  border: 1px solid transparent;
}

html,
body {
  font-family: $font-family-monospace;
  overflow-x: clip;
  background-color: #f5f5f5 !important;
}

.bg-white {
  background: #fff !important;
}

.bg-gradient-primary {
  background-image: linear-gradient(
    227.02deg,
    #ff8008 -43.37%,
    #df4040 74.12%
  ) !important;
}

.text-gradient-primary {
  @include font-gradient();
}

.navbar-primary {
  background: $bg-primary !important;
  color: white !important;
}

.fixed-top {
  &.navbar-primary {
    height: 60px !important;
  }
  &.bg-transparent {
    height: 80px !important;
    .momo-nav-toggle {
      top: 80px !important;
    }
  }
}
.header-navbar {
  position: relative;
  .navbar-container {
    padding: 0rem 1.4rem !important;
  }
}

.nav-vertical {
  margin-top: 24px;
  .nav {
    &.nav-tabs {
      &.nav-right {
        float: left;
        display: table;
        margin-left: 0;
        .nav-item {
          .nav-link {
            justify-content: start;
            padding: 12px 32px !important;
            color: $color-gray-3;
          }
        }
      }
      .nav-item {
        .nav-link {
          &:hover {
            background-color: $color-gray-0;
          }
          &.active {
            background: linear-gradient(
              227.02deg,
              #ff8008 -43.37%,
              #df4040 74.12%
            );
            /* -webkit-background-clip: text; */
            border-image: linear-gradient(to bottom, #ff8008, #df4040) 0 1;
            -webkit-text-fill-color: $primary;
            border-left: 4px solid $primary;
            background: #fae0e0 !important;
            padding: 12px 28px !important;
            &::after {
              left: auto;
              right: 2.5999999999999996rem;
              transform: rotate(90deg) translate3d(0, 225%, 0);
              top: 1.25rem;
              width: 0.14000000000000057rem;
            }
          }
        }
      }
    }
  }
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user {
  margin-right: -1rem;
}

//Breadcrumb
app-content-header {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  position: relative;
  display: block;
  width: 100%;
  background-color: #fff;
  z-index: 4;
  height: 52px;
  &.sticky-top {
    z-index: 990 !important;
  }
  .badge {
    font-weight: 500 !important;
    &.badge-up {
      right: -10px;
      width: 18px;
      height: 18px;
      font-size: 16px;
      line-height: 19px;
      padding-left: 3px;
      padding-right: 3px;
      border: 1px solid white;
    }
    &.badge-primary {
      background: $primary-gradient !important;
    }
  }
  .c-gray-b1 {
    color: #b1b1b1;
  }
  .c-gray-c4 {
    color: #c4c4c4;
  }
  .noti-content {
    width: 383px;
    position: relative;
    height: 425px;
    .noti-item {
      &:hover {
        background: $color-gray-0;
        cursor: pointer;
      }
      &.unread {
        background: #ffeaea;
        &.unread:hover {
          background: #ffd4d4;
        }
      }
    }
  }
}
.content-header {
  height: 52px;
  .breadcrumb {
    display: flex !important;
    padding-left: 0px;
    span {
      color: $color-gray-2;
    }
    .breadcrumb-item + .breadcrumb-item:before {
      height: 24px;
      color: $color-gray-3 !important;
    }
  }
}

html body .app-content {
  padding: 0 !important;
}

//Pagination
ngb-pagination > ul > {
  li:nth-child(1) > a,
  li:nth-child(2) > a,
  li:nth-child(8) > a,
  li:nth-child(9) > a {
    background-color: #d9d9d9;
  }
}
ngb-pagination > ul > li > a {
  margin-left: 3px !important;
  margin-right: 3px !important;
}
.custom-pagination {
  .page-link {
    background-color: #f5f5f5;
  }
}
.page-link {
  background-color: transparent;
  border-radius: 32px !important;
  color: #b5b5c3 !important;
  font-size: 28px !important;
  i {
    line-height: 1.3rem;
    padding: 0.37rem;
    font-size: 0.857rem !important;
  }
}
.page-item {
  &.disabled {
    .page-link {
      color: $color-gray-2 !important;
      background-color: #e2e2e2;
      i {
        color: $color-gray-2;
      }
    }
  }
  &.active {
    background-color: #ffffff00;
    .page-link {
      padding: 0.1rem;
      font-weight: 900;
      background: linear-gradient(227.02deg, $flamenco -43.37%, $punch 74.12%);
    }
  }
  .page-link {
    padding: 0.1rem;
    font-weight: 900;
    &:hover {
      padding: 0.1rem;
      background-color: $color-gray-1;
      color: $primary;
    }
  }
  a[aria-label="Next"],
  a[aria-label="Last"],
  a[aria-label="Previous"],
  a[aria-label="First"] {
    background-color: #d9d9d9;
    i {
      color: $color-gray-2;
    }
    &:hover {
      background-color: $color-gray-1;
      color: $primary;
    }
  }
}
// pagination

.no-data {
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  align-self: stretch;
  min-height: 200px;

  &.loading .no-data-content {
    // background: #ebebeb;
    // padding: 1rem 8.5rem;
    // border-radius: 1rem;
  }

  .no-data-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.card {
  border-radius: 1rem;
  &.no-box-shadow {
    box-shadow: none !important;
  }
}

.menu-list-group {
  margin-bottom: 1rem;
  .menu-list-group-item {
    display: flex;
    padding: 1rem 1.5rem !important;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover,
    &:focus {
      background: #f5f5f5;
    }
    &:active,
    &.active {
      background-color: #ffe6e6;
      color: black;
    }
  }
}

.paginate-link:not(.disabled),
.btn-paginate-link {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  &:hover,
  &:focus {
    background: #f5f5f5;
  }
  &:active {
    background-color: #ffe6e6;
    h5 {
      color: #df4040;
    }
  }
}
.btn-paginate-link {
  background: #f5f5f5;
  &:hover {
    background-color: #ffeeee;
  }
}

.progress-bar-primary .progress-bar {
  background-color: linear-gradient(
    227.02deg,
    #ff8008 -43.37%,
    #df4040 74.12%
  ) !important;
}

.bg-primary {
  background-color: linear-gradient(
    227.02deg,
    #ff8008 -43.37%,
    #df4040 74.12%
  ) !important;
}

/* Style the iframe to fill the container */
.momo-container {
  max-width: unset;
  margin-left: auto !important;
  margin-right: auto !important;
}

@include sm {
  .momo-container,
  .momo-container-sm {
    max-width: $screen-sm-min;
  }
}

@include md {
  .momo-container,
  .momo-container-sm,
  .momo-container-md {
    max-width: $screen-md-min;
  }
}

@include lg {
  .momo-container,
  .momo-container-sm,
  .momo-container-md,
  .momo-container-lg {
    max-width: $screen-lg-min;
  }
}

@include xl {
  .momo-container,
  .momo-container-sm,
  .momo-container-md,
  .momo-container-lg,
  .momo-container-xl {
    max-width: $screen-xl-min;
  }
}

@include xxl {
  .momo-container,
  .momo-container-sm,
  .momo-container-md,
  .momo-container-lg,
  .momo-container-xl {
    max-width: $screen-xxl-min;
  }
}

.collapse-icon {
  .paginate-link {
    // background: #ffeeee;
    padding: 0;
    &:hover,
    &:hover i {
      color: #df4040 !important;
    }

    &:active {
      background-color: unset;
    }
  }
  .collapse-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
    .collapse-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;
      h4 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;

        overflow: hidden;
        color: #656565;
        text-overflow: ellipsis;
      }

      .collapse-status {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        align-self: stretch;
        font-size: 1rem;
        color: #999;
      }
    }
  }

  .accordion {
    .card {
      padding: 1rem;
      border-radius: 1rem;
      transition-delay: -1s;

      &:hover,
      &:focus,
      &.active {
        background: #f5f5f5;
      }
      .card-header button {
        padding: 0;
      }
    }
  }

  [aria-expanded="true"] {
    .fa-language,
    h4 {
      color: #df4040 !important;
    }
  }

  .card-header {
    position: relative;
  }

  .card {
    border-bottom: none !important;
    /* border-bottom: 1px solid #ebe9f1; */
  }

  [aria-expanded="true"]:after,
  .collapse-icon [aria-expanded="false"]:after {
    top: 55%;
  }

  [data-toggle="collapse"]:after {
    position: absolute;
    top: 38%;
    right: 1rem;
    margin-top: -8px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 14px;
    width: 14px;
    height: 11px;
    content: "";
    transition: all 0.3s linear 0s;
  }

  [data-toggle="collapse"]:disabled:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
  }

  [aria-expanded="false"]:after {
    transform: rotate(-180deg);
  }
}

.course-list {
  display: flex;
  // align-items: center;
  //   justify-content: center;
  gap: 1rem;
  align-self: stretch;
  flex-wrap: wrap;

  .course-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    // flex: 1 0 0;
    @media (min-width: 0px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media (min-width: 576px) {
      flex: 0 0 50%;
      max-width: 47%;
    }
    @media (min-width: 768px) {
      //   flex: 0 0 50%;
      //   max-width: 48%;
      flex: 0 0 33.3%;
      max-width: 31%;
    }
    @media (min-width: 992px) {
      flex: 0 0 33.3%;
      max-width: 31%;
    }
    @media (min-width: 1200px) {
      flex: 0 0 20%;
      max-width: 18%;
    }
    // @media (min-width: 1600px) {
    //   flex: 0 0 16.6%;
    //   max-width: 15.6%;
    // }
    // max-width: 260px;
    border-radius: 0.75rem;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

    img {
      max-height: 8.5625rem;
      //   flex-shrink: 0;
      //   align-self: stretch;
      border-radius: 0.5rem 0.5rem 0rem 0rem;
    }
    .course-content {
      display: flex;
      padding: 1rem;
      flex-direction: column;
      //   align-items: center;
      gap: 0.5rem;
      align-self: stretch;

      .course-content-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
      }
    }
  }
}

.input-group > .input-group-prepend > .input-group-text {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.input-group > .form-control:not(:first-child) {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
:not(.input-group) > .form-control {
  border-radius: 0.75rem;
}

.btn-primary {
  border-radius: 0.8125rem;
  background: linear-gradient(270deg, #ff8008 -50.76%, #df4040 100%);
}
.btn-primary-modal {
  color: $color-white !important;
  background: $primary-gradient !important;

  &:hover,
  &:focus {
    color: $color-white !important;
    background: $primary-gradient !important;
    box-shadow: 0px 6px 16px rgba(223, 64, 64, 0.4) !important;
  }
}
.btn-secondary-modal {
  color: $primary-gradient;
  background-color: $color-gray-1;

  &:hover,
  &:focus {
    color: $primary-gradient !important;
    background-color: $color-gray-0 !important;
  }
}
.btn-success-modal {
  color: $color-white !important;
  background: $primary-gradient !important;

  &:hover,
  &:focus {
    color: $color-white !important;
    background: $primary-gradient !important;
  }
}
.btn-danger-modal {
  color: $color-white !important;
  background-color: $danger !important;

  &:hover,
  &:focus {
    color: $color-white !important;
    background-color: $danger !important;
    box-shadow: 0px 6px 16px rgba(223, 64, 64, 0.4) !important;
  }
}
.btn-outline-success-modal {
  color: $success;
  background-color: transparent;
  border: 1px solid $color-gray-1;

  &:hover,
  &:focus {
    color: $success !important;
    border: 1px solid $color-gray-1 !important;
    background-color: transparent !important;
  }
}
.btn-outline-danger-modal {
  color: $primary;
  background-color: transparent;
  border: 1px solid $color-gray-1;
  &:hover,
  &:focus {
    color: $primary !important;
    border: 1px solid $color-gray-1 !important;
    background-color: $color-gray-0 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  }
}

.btn-close-modal {
  color: $color-gray;
  background-color: $color-gray-1;
  transition: all 0.5s ease;
  margin: 2px 0;
  &:hover {
    @include font-gradient();
    background-color: transparent;
    border: 2px solid $color-gray-1;
    margin: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  }
}

.btn-confirm-modal {
  color: $color-white;
  background: $primary-gradient;
  transition: all 0.5s ease;
  margin: 2px 0;
  &:hover {
    background: $primary-gradient !important;
    border: 2px solid $primary;
    box-shadow: 0px 6px 16px rgba(223, 64, 64, 0.4) !important;
    margin: 0;
    .btn-text {
      color: #333333;
    }
  }
}

.btn-outline-primary {
  &:hover {
    box-shadow: 0px 6px 16px rgba(223, 64, 64, 0.4) !important;
  }
}

.btn-normal {
  width: 210px !important;
}
.btn-modal {
  width: 48%;
  font-size: $font-size-h4;
  line-height: $line-height-h4;
}

.skeleton-loader {
  background: #eff1f6 !important;
}

.course-list-group {
  margin-bottom: 1rem;
  .course-list-group-item {
    &.item-rounded {
      border-radius: 1rem;
    }
    // display: flex;
    padding: 1rem 1.5rem !important;
    flex-direction: column;
    // align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover,
    &:focus {
      background: #f5f5f5;
    }
    &:active,
    &.active {
      background-color: #ffe6e6;
      color: black;
    }
  }
}

.text-ellipsis-1,
.text-ellipsis-2 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    overflow: visible;
    -webkit-line-clamp: inherit;
  }
}

// .course-card:hover{
//   .text-ellipsis-1,.text-ellipsis-2{
//     overflow: visible;
//     -webkit-line-clamp: inherit;
//   }
// }

.text-ellipsis-2 {
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@import "@core/scss/angular/libs/toastr.component.scss";

.toast,
.toast-title {
  font-size: $font-size-h4 !important;
}
.toast-message {
  font-size: 20px !important;
  line-height: 20px;
}
#toast-container {
  > div {
    border-radius: 1rem !important;
  }
}
.toast-container {
  .ngx-toastr {
    width: 335px !important;
    margin-bottom: 8px !important;
  }
}
.toast-error {
  .toast-title {
    color: #fff !important;
  }
}
.toast-success {
  background-size: 18px !important;
  .toast-title {
    color: #fff !important;
  }
}
.toast-close-button {
  font-size: 24px !important;
  // top: 0.1em !important;
  right: 0 !important;
  text-shadow: none !important;
}

.apexcharts-canvas {
  // Text font family
  .apexcharts-text,
  .apexcharts-datalabel {
    font-family: $font-family-monospace !important;
  }
}

// course list horizon
.course-no-wrap,
.course-loading {
  display: flex;
  gap: 1rem;

  .course-card {
    cursor: pointer;
    display: flex;
    width: 15.2rem;
    flex-direction: column;
    align-items: center;
    border-radius: 0.75rem;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;

    img {
      height: 8.5625rem;
      flex-shrink: 0;
      // align-self: stretch;
      object-fit: contain;
    }

    .course-body {
      display: flex;
      padding: 1rem;
      flex-direction: column;
      gap: 0.5rem;
      align-self: stretch;
      width: 14.2rem;
    }
  }
}

.course-no-wrap > .course-card:hover {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(227.02deg, #ff8008 -43.37%, #df4040 74.12%) border-box;
  border: 1px solid transparent;
  transform: translateY(-5px) scale(1.005);
  z-index: 999;
}

.course-loading {
  overflow: hidden;
}
// course list horizon

// loading spinner
.loading-centered {
  height: 100%;
}

#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
  z-index: 99999;
  pointer-events: none;
}

.loading-logo {
  position: absolute;
  left: calc(50% - 95px);
  top: 35%;
}

.loading.loading-home {
  top: 55% !important;
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;

  .effect-1,
  .effect-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgba(223, 64, 64, 1);
    // border-left: 3px solid rgba(221, 121, 87, 1);
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .effect-1 {
    animation: rotate 1s ease infinite;
  }

  .effect-2 {
    animation: rotateOpacity 1s ease infinite 0.1s;
  }
  .effect-3 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgba(223, 64, 64, 1);
    // border-left: 3px solid rgba(221, 121, 87, 1);
    -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
    animation: rotateOpacity 1s ease infinite 0.2s;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .effects {
    transition: all 0.3s ease;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
// loading spinner
