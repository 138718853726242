//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Custom variables
// $base-font-size: 15px;
$base-font-size: 18px;

$font-size-h1: 38px;
$font-size-h2: 32px;
$font-size-h3: 26px;
$font-size-h4: 22px;
$font-size-h5: 18px;
$font-size-h6: 16px;

$line-height-h1: 42px;
$line-height-h2: 36px;
$line-height-h3: 30px;
$line-height-h4: 24px;
$line-height-h5: 22px;
$line-height-h6: 18px;
